import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import TextField from "../../common/TextField"
import UMCButton from "../../common/UMCButton"
import PasswordField from "../../common/PasswordField"
import { setSubHeading } from "../../../store/reducers/CommonReducer"
import aleLogo from "../../../assets/images/ale-logo.jpg"

const OxeLogin = (props) => {

    const [error, setError] = useState("")
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setSubHeading("Welcome!"))
    })

    const onFinish = (values) => {
        console.log("Success:", values)
        // dispatch(setLoader(true));
    }

    const updateUsername = (value) => {
        setUserName(value)
    }

    const updatePassword = (value) => {
        setPassword(value)
    }


    return <>

        <img src={aleLogo} alt="ALE-logo" className="ale-logo" />
        {/* <p className="login-status">Access to the OXE configuration services</p> */}
        <div className="field-wrap">
            <div className="oxe-login-label">Login</div>
            <TextField
                className="input-text"
                placeholder="Enter superadmin login account"
                handleBlur={updateUsername}
            />
        </div>
        <div className="field-wrap">
            <div className="oxe-login-label">Enter your OXE account password</div>
            <PasswordField
                className="input-text"
                placeholder="Superadmin password"
                handleBlur={updatePassword}
            />
        </div>

        <UMCButton
            className="login-form-button"
            label="Login"
            type="primary"
            onClick={onFinish}
            disabled={!(userName && password)}
        />

    </>
}

export default OxeLogin