import { Layout } from "antd";
import logoMax from "../../assets/images/logo_UMC_maxi.svg"
import logoMin from "../../assets/images/logo_UMC_mini.svg"
import buttonMax from "../../assets/images/button_menu_maxi.svg"
import buttonMin from "../../assets/images/button_menu_mini.svg"
import oxeIcon from "../../assets/images/icon_OXE.svg"
import sipTrunkIcon from "../../assets/images/icon_sip_trunk.svg"
import subscriptionIcon from "../../assets/images/icon_subscriptions.svg"
import usersIcon from "../../assets/images/icon_users.svg"
import UMCButton from "../common/UMCButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedApp } from "../../store/reducers/CommonReducer";
import { OXE_STATUS, USER_DEF_TEMPLATE, SIP_TRUNK_PROF_MGT, UMC_LOGS } from "../../utils/Constants";
import { useWindowSize } from "../customHooks/useWindowSize";
import { PURPLE, WHITE } from "../../styles/styleUtils";
import { InfoCircleFilled } from "@ant-design/icons";


const siderStyle = {
    textAlign: "center",
    color: WHITE,
    backgroundColor: PURPLE,
};

const UMCSidebar = (props) => {

    const [collapsed, setCollapsed] = useState(false)
    const selectedApp = useSelector((state) => state.common.selectedApp)
    const [width] = useWindowSize();
    // const oxeUmcConnected = useSelector((state) => state.common.oxeUmcConnected)
    const oxeUmcConnected = true
    const dispatch = useDispatch()

    useEffect(() => {
        if (width < 850) setCollapsed(true)
        else setCollapsed(false)
    }, [width])

    const handleAppSelect = (app) => {
        dispatch(setSelectedApp(app))
    }

    return <Layout.Sider
        style={siderStyle}
        collapsible
        collapsed={collapsed}
        trigger={null}
        width={260}
    >
        <div>
            <div id="mainNav" className={collapsed ? "expand" : "collapse"}>
                <div id="logoField">
                    {!collapsed ?
                        <span>
                            <img src={logoMax} alt="LogoUMC" className={"logo-umc-min"} />
                            <UMCButton onClick={() => setCollapsed(!collapsed)} icon={<img src={buttonMin} alt="Expand" title="Collapse" />} className="toggleButton" />
                        </span>
                        : <span>
                            <UMCButton onClick={() => setCollapsed(!collapsed)} icon={<img src={buttonMax} alt="Expand" title="Expand" />} className="toggleButton" />
                            <img src={logoMin} alt="LogoUMC" className={"logo-umc-max"} />
                        </span>}
                </div>
                <nav>
                    <div id="adminMode">
                        <InfoCircleFilled />
                        <div className="mode-label">Super administrator mode</div>
                    </div>
                    <div id="configMenu">
                        <UMCButton
                            disabled={!oxeUmcConnected}
                            className={`button buttonMenu ${selectedApp === OXE_STATUS ? "active" : ""}`}
                            onClick={() => handleAppSelect(OXE_STATUS)}
                            icon={<img src={oxeIcon} alt="Users icon" />}
                            label={collapsed ? "" : "Oxe status in UMC"}
                            title={collapsed ? "Oxe status in UMC" : ""}
                        />
                        <UMCButton
                            disabled={!oxeUmcConnected}
                            className={`button buttonMenu ${selectedApp === USER_DEF_TEMPLATE ? "active" : ""}`}
                            onClick={() => handleAppSelect(USER_DEF_TEMPLATE)}
                            icon={<img src={usersIcon} alt="SIP trunk icon" />}
                            label={collapsed ? "" : "User default template"}
                            title={collapsed ? "User default template" : ""}
                        />
                        <UMCButton
                            disabled={!oxeUmcConnected}
                            className={`button buttonMenu ${selectedApp === SIP_TRUNK_PROF_MGT ? "active" : ""}`}
                            onClick={() => handleAppSelect(SIP_TRUNK_PROF_MGT)}
                            icon={<img src={sipTrunkIcon} alt="WBM icon" />}
                            label={collapsed ? "" : "SIP trunk profile management"}
                            title={collapsed ? "SIP trunk profile management" : ""}
                        />
                        <UMCButton
                            disabled={!oxeUmcConnected}
                            className={`button buttonMenu ${selectedApp === UMC_LOGS ? "active" : ""}`}
                            onClick={() => handleAppSelect(UMC_LOGS)}
                            icon={<img src={subscriptionIcon} alt="WBM icon" />}
                            label={collapsed ? "" : "UMC logs"}
                            title={collapsed ? "UMC logs" : ""}
                        />

                    </div>
                </nav>
            </div>
            <div id="bottom-nav" className={collapsed ? "expand" : "collapse"}>

            </div>
        </div>
    </Layout.Sider>
}

export default UMCSidebar