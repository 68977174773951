import { useSelector } from "react-redux"
import { SIP_TRUNK_PROF_MGT, USER_DEF_TEMPLATE } from "../../utils/Constants"

const UMCSubHeader = () => {

    const selectedApp = useSelector(state => state.common.selectedApp)
    const subHeading = useSelector(state => state.common.subHeading)

    return <>
        {(subHeading) ?
            <div className="sub-header">{subHeading}</div>
            : <></>}
    </>
}

export default UMCSubHeader