import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setSubHeading } from "../../../store/reducers/CommonReducer"

const OxeStatus = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setSubHeading("Search an OXE"))
    }, [])

    return <>OxeStatus</>
}

export default OxeStatus