import { OXE_LOGIN, SIP_TRUNK_PROF_MGT, USER_DEF_TEMPLATE } from "../utils/Constants"

/* Colors */
export const PURPLE = "#6639B7"
export const LIGHT_PURPLE = "#A3A0FB"
export const LIGHT_PURPLE2 = "#c2b0e2"
export const DARK_PURPLE = "#381971"
export const GREY = "#F0F0F7"
export const WHITE = "#FFFFFF"
export const DARK_GRAY = "#4D4F5C"
export const PALE_DARK_GRAY = "#00000076"
export const LIGHT_GRAY = "#f1f1f3"
export const DARK = "#43425D"

export const DEFAULT_BTN = {
    colorPrimary: PURPLE,
    colorPrimaryHover: DARK_PURPLE,
    colorPrimaryActive: DARK_PURPLE,
    colorTextDisabled: WHITE,
    borderRadius: "4px",
    fontSize: "0.9em"
}

export const DEFAULT_RADIO_BTN = {
    radioSize: 16,
    dotSize: 8,
    buttonSolidCheckedHoverBg: DARK_PURPLE,
    buttonBg: LIGHT_GRAY,
}

export const DEFAULT_SELECT = {
    optionSelectedBg: PURPLE,
    optionSelectedColor: WHITE,
    optionActiveBg: LIGHT_PURPLE2
}

export const DEFAULT_TABLE = {
    headerBg: DARK_GRAY,
    headerSortActiveBg: DARK,
    headerSortHoverBg: DARK,
    headerSplitColor: PALE_DARK_GRAY,
    headerBorderRadius: 2,
    rowHoverBg: DARK_PURPLE
}

export const COMMON_CONTENT_STYLE = {
    color: "#000",
    backgroundColor: "#fff",
    margin: "0 50px 15px",
    padding: "15px",
    maxHeight: "calc(100vh - 160px)",
    fontSize: "12px",
    overflow: "auto"
}

export const DEFAULT_DIVIDER = {
    colorText: PURPLE,
    fontSize: "22px",
}

export const DEFAULT_STEPS = {
    fontSize: "13px",
    fontSizeLG: "13px",
    colorPrimary: PURPLE,
    iconTop: -1,
    titleLineHeight: 15,
    colorText: PURPLE,
    colorTextDescription: DARK,
}

export const PAGINATION = {
    itemActiveBg: PURPLE,

}

export const HEADER_STYLE = {
    height: 60,
    lineHeight: "60px",
    backgroundColor: WHITE,
    padding: "0 10px"
};

export const overrideComContentStyle = (app, style, showSubHeading) => {

    if (!showSubHeading) {
        style.margin = "1.5em 4.5em"
        style.maxHeight = "calc(100vh - 120px)"
        style.padding = 0
    } else if (app === OXE_LOGIN) {
        style.textAlign = "center"
    } 

    return style
}

export const getBaseFontSize = (width) => {

    if (width <= 1280) return 12
    else if (width > 1280) return 15

}