import { createSlice } from '@reduxjs/toolkit'
import { OXE_LOGIN } from '../../utils/Constants'

export const CommonReducer = createSlice({
    name: 'common',
    initialState: {
        authStatus: false,
        loading: false,
        language: navigator.language || "en-US",
        selectedApp: OXE_LOGIN,
        subHeading: "",
    },
    reducers: {
        setAuthStatus: (state, action) => {
            state.authStatus = action.payload
        },
        setLoader: (state, action) => {
            state.loading = action.payload
        },
        setLanguage: (state, action) => {
            state.loading = action.payload
        },
        setSelectedApp: (state, action) => {
            state.selectedApp = action.payload
            state.subHeading = ""
        },
        setSubHeading: (state, action) => {
            state.subHeading = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setAuthStatus, setLoader, setLanguage, setSelectedApp, setSubHeading } = CommonReducer.actions

export default CommonReducer.reducer