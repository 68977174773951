import { useSelector } from "react-redux";
import { ConfigProvider, Layout, Spin } from "antd";
import UMCSidebar from "./components/layout/UMCSidebar";
import UMCHeader from "./components/layout/UMCHeader";
import UMCFooter from "./components/layout/UMCFooter";
import UMCContent from "./components/layout/UMCContent";
import UMCSubHeader from "./components/layout/UMCSubHeader";
import { DEFAULT_BTN, DEFAULT_RADIO_BTN, DEFAULT_SELECT, DEFAULT_TABLE, DEFAULT_DIVIDER, DEFAULT_STEPS, GREY, PURPLE, getBaseFontSize, PAGINATION } from "./styles/styleUtils";
import { useWindowSize } from "./components/customHooks/useWindowSize";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

function App() {

  const loading = useSelector(state => state.common.loading)
  const [width] = useWindowSize();
  const [fontSize, setFontSize] = useState()

  useEffect(() => {
    setFontSize(getBaseFontSize(width))
  }, [width])

  return (
    <ConfigProvider
      theme={{ // To override antd's default style globally
        token: {
          fontSize: fontSize,
          fontFamily: "Noto Sans",
          colorPrimary: PURPLE
        },
        components: {
          Button: DEFAULT_BTN,
          Radio: DEFAULT_RADIO_BTN,
          Select: DEFAULT_SELECT,
          Table: DEFAULT_TABLE,
          Divider: DEFAULT_DIVIDER,
          Steps: DEFAULT_STEPS,
          Pagination: PAGINATION
        }
      }}
    >
      <Spin spinning={loading} indicator={<LoadingOutlined/>} size="large" wrapperClassName={loading ? "spin-active" : "spin-idle"}>
        <Layout>
          <Layout>
            <UMCSidebar />
            <Layout style={{ backgroundColor: GREY }}>
              <UMCHeader />
              <UMCSubHeader />
              <UMCContent />
            </Layout>
          </Layout>
          <UMCFooter />
        </Layout>
      </Spin>
    </ConfigProvider>
  );
}

export default App;
