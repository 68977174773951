import { Button } from "antd"
import { forwardRef } from "react"

const UMCButton = forwardRef(function UMCButton(props, ref) {
    
    return <Button
        ref={ref}
        type={props.type}
        style={props.customStyle}
        className={props.className}
        icon={props.icon && <>{props.icon}</>}
        size={props.size}
        onClick={()=>props.onClick && props.onClick()}
        danger={props.danger}
        ghost={props.ghost}
        disabled={props.disabled}
        title={props.title}
    >
        {props.label}
    </Button>
})

export default UMCButton