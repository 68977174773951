import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// SSO auth to be added
const ProtectedRoutes = (props) => {

    const [authState, setAuthState] = useState(true) //Change to false for enabling auth

    return authState && <Outlet />
}

export default ProtectedRoutes;
