import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Layout } from "antd";
import { OXE_STATUS, UMC_LOGS, SIP_TRUNK_PROF_MGT, USER_DEF_TEMPLATE, OXE_LOGIN } from "../../utils/Constants";
import { COMMON_CONTENT_STYLE, overrideComContentStyle } from "../../styles/styleUtils";
import UsersDefTemplate from "../pages/UsersDefTemplate/UsersDefTemplate";
import SIPTrunkProfMgt from "../pages/SIPTrunkProfMgt/SIPTrunkProfMgt";
import UMCLogs from "../pages/UMCLogs/UMCLogs";
import OxeLogin from "../pages/OxeLogin/OxeLogin";
import OxeStatus from "../pages/OxeStatus/OxeStatus";


const UMCContent = (props) => {

    const [contentStyle, setContentStyle] = useState(COMMON_CONTENT_STYLE)
    const selectedApp = useSelector(state => state.common.selectedApp)
    const subHeading = useSelector(state => state.common.subHeading)

    useEffect(() => {
        let contentStyleTemp = { ...COMMON_CONTENT_STYLE }
        setContentStyle(overrideComContentStyle(selectedApp, contentStyleTemp, subHeading))

    }, [selectedApp, subHeading])


    return <Layout.Content style={contentStyle}>
        {selectedApp === OXE_LOGIN && <OxeLogin />}
        {selectedApp === OXE_STATUS && <OxeStatus />}
        {selectedApp === USER_DEF_TEMPLATE && <UsersDefTemplate />}
        {selectedApp === SIP_TRUNK_PROF_MGT && <SIPTrunkProfMgt />}
        {selectedApp === UMC_LOGS && <UMCLogs />}
    </Layout.Content>
}

export default UMCContent