import { useDispatch } from "react-redux"
import { setSubHeading } from "../../../store/reducers/CommonReducer"
import { useEffect } from "react"

const UMCLogs = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setSubHeading("UMC Logs"))
    }, [])

    return <>UMCLogs</>
}

export default UMCLogs