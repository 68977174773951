import { applyMiddleware, configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger';
import { thunk } from 'redux-thunk';
import CommonReducer from './reducers/CommonReducer.js'

const logger = createLogger();
const middleware = applyMiddleware(thunk, logger);
const reducer = {
  common: CommonReducer,
}

export default configureStore({ reducer }, middleware, logger)