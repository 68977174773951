import { Layout } from "antd";

const UMCFooter = () => {

    return <Layout.Footer>
        <div id="mainFooter">
            <p id="footerTerms">&copy; 2024 ALE International | Terms of use | Privacy | Terms &amp; Conditions | Software License</p>
            <p id="footerContact">Contact email: <a href="mailto:UMC@al-enterprise.com">UMC@al-enterprise.com</a></p>
        </div>
    </Layout.Footer>
}

export default UMCFooter