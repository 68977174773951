import { Input } from "antd"
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons"
import { PURPLE } from "../../styles/styleUtils"

const PasswordField = (props) => {
  return <Input.Password
    className={`${props.className}`}
    onBlur={props.handleBlur}
    iconRender={(visible) => (visible ? <EyeFilled style={{ color: PURPLE }} /> : <EyeInvisibleFilled style={{ color: PURPLE }} />)}
    {...props}
  />
}

export default PasswordField