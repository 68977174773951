/* API URLs */
const REST_PROTOCOL = window.location.protocol
export const ORIGIN = window.location.origin
const API_VERSION = "v1.0"

export const IAM_SSO_URL = !window.location.port ? ORIGIN + `/api/${API_VERSION}/umc/iam-sso` :  `${REST_PROTOCOL}//${process.env[`REACT_APP_${process.env.NODE_ENV.toUpperCase()}_IAM_SSO_URL`]}`


/* Application list */
export const OXE_LOGIN = "OXE_LOGIN"
export const OXE_STATUS = "OXE_STATUS"
export const USER_DEF_TEMPLATE = "USER_DEF_TEMPLATE"
export const SIP_TRUNK_PROF_MGT = "SIP_TRUNK_PROF_MGT"
export const UMC_LOGS = "UMC_LOGS"


/* Other Constants */

export const SUCCESS = "SUCCESS"
export const STRING = "STRING"
export const SIMPLE_ENUM = "SIMPLE_ENUM"
export const TRUE = "true"