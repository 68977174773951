import { STRING, TRUE } from "./Constants";

export const validateRegex = (regexp, value) => {
    let regex = new RegExp(regexp);
    if (regex.test(value)) return true
    else return false
}

export const parseEnum = (original) => {
    let parsedEnum = []

    original?.length && original.map(item => {

        parsedEnum.push({
            label: item.name,
            value: item.id,
        })
    })

    return parsedEnum
}

export const filterAttrByType = (attribute, filterVal, filterType) => {

    if (filterType === STRING)
        return attribute.toLowerCase().includes(filterVal)
    else
        return attribute?.some(enumItem => (enumItem.id.toLowerCase() === filterVal && enumItem.value === TRUE))
}

export const tableListFilter = (list, searchVal) => {
    let filteredArr = []
    filteredArr = list.filter(user => {
        console.log(user)
        return Object.entries(user?.attribute).some(([key, value]) => {
            if (typeof value === "string" && value?.toLowerCase().includes(searchVal.toLowerCase())) return true
            else return false
        });
    })

    return filteredArr
}

export const isListItemExist = (list, key) => {
    return list.some(item => item === key)
}

export const getTranslated = (dictionary, key) => {
    if (dictionary?.length) {
        return (dictionary[key]) ? dictionary[key] : key
    }
}