import { Input } from "antd"
import { forwardRef, useEffect, useState } from "react"
import { validateRegex } from "../../utils/CommonUtils"

const TextField = forwardRef((props, ref) => {

    const [error, setError] = useState("")
    const [value, setValue] = useState()
    const [readOnly, setReadOnly] = useState(false)

    useEffect(() => {
        if (!props.value && !props.valueLocale && props.defaultValue !== "") {
            setValue(props.defaultValue)
            // setReadOnly(applyROAccess(props.access, props.actionType))
        }
        else {
            setValue(props.valueLocale ? props.valueLocale : props.value)
        }
    }, [props.value, props.defaultValue])

    const handleChange = (event) => {
        const value = event?.target?.value

        if (props.regexp) {
            if (validateRegex(props.regexp, value)) {
                setError("")
                setValue(value)
                props.handleChange && props.handleChange(value)
            } else {
                setError("error")
            }
        } else {
            setValue(value)
            props.handleChange && props.handleChange(value)
        }
    }

    const handleBlur = () => {
        if (props.handleBlur && !readOnly)
            props.handleBlur(value, props.id)
    }

    return <Input
        {...props}
        ref={ref}
        className={`${props.className}`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        status={error}
        readOnly={readOnly}
        onPressEnter={props.onPressEnter}
        onClick={props.onClick}
        suffix={props.suffix}
    />

})

export default TextField