import { Dropdown, Layout } from "antd";
import { HEADER_STYLE } from "../../styles/styleUtils";
import { useDispatch } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import avator from "../../assets/images/icon_avatar.svg"

const UMCHeader = (props) => {

    const dispatch = useDispatch()

    const menuItems = [
        {
            key: "logout",
            label: "Logout",
            danger: true,
            icon: <LogoutOutlined />
        },
    ]

    return <Layout.Header style={HEADER_STYLE}>
        <div className="header-div" style={{ display: "flex", alignItems: "center", justifyContent: "right", height: "inherit" }}>

        <div className="header-item">
                <Dropdown.Button
                    menu={{ items: menuItems }}
                    placement="bottom"
                    icon={<img src={avator} alt="User profile" className="header-icon" />}
                >
                    John Doe
                </Dropdown.Button>
            </div>
           
        </div>
    </Layout.Header>
}

export default UMCHeader